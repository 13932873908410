import { IAction, IState } from "../../../../shared/interfaces/common";
import { CustomerActionTypes } from "../../actions/customers/customer.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();


export const searchChainsReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case CustomerActionTypes.SEARCH_CHAINS: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case CustomerActionTypes.SEARCH_CHAINS_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case CustomerActionTypes.SEARCH_CHAINS_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
     
        default: {
            return state;
        }
    }
}
