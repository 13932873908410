import { IAction, IState } from "../../../../shared/interfaces/common";
import { FlyerActionTypes } from "../../actions/flyer/flyers.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();


export const rejectRequestNewShopReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case FlyerActionTypes.REJECT_REQUEST_NEW_SHOP: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case FlyerActionTypes.REJECT_REQUEST_NEW_SHOP_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case FlyerActionTypes.REJECT_REQUEST_NEW_SHOP_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
     
        default: {
            return state;
        }
    }
}
