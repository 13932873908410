import Type from '../../type';
import {getApiController} from '../../resource';
import {IResource} from '../../../../../shared/interfaces/common/resource';

const SearchFlyers = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/Search?page=${payload.page}&pageSize=${payload.pageSize}&sakayaId=${payload.sakayaId ?? ''}&keyword=${payload.keyword ?? ''}&statuses=${payload.statuses ?? ''}`
});

const UpdateFlyer = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/UpdateFlyer`
});

const CreateFlyer = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/CreateFlyer`
});

const AddCustomPrice = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/AddCustomPrice`
});

const DeleteFlyer = (id: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/DeleteFlyer?id=${id}`
});

const ImportFlyer = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/ImportFlyer`
});

const RegisterFlyer = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/RegisterFlyer`
});

const GetImporFlyerHistory = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/ImporFlyerHistory?page=${payload.page}&pageSize=${payload.pageSize}&sakayaId=${payload.sakayaId ?? ''}&StartDate=${payload.startDate}&EndDate=${payload.endDate}&ignoreLogs=true`
});

const FetchMaxSeat = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/FetchMaxSeat?horecaId=${payload.horecaId}`
});

const AcceptRequest = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/AcceptRequest`
});

const RejectRequestNewShop = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/flyers/v1.0/RejectRequestNewShop`
});

export default {
    SearchFlyers,
    UpdateFlyer,
    CreateFlyer,
    AddCustomPrice,
    FetchMaxSeat,
    DeleteFlyer,
    ImportFlyer,
    RegisterFlyer,
    GetImporFlyerHistory,
    AcceptRequest,
    RejectRequestNewShop
};

