import {SitecoreResource} from '../../../gateway/api/resource';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';

const searchFlyers = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.SearchFlyers(payload);
   
    return apiGateway.doGetRequest(flyerResource);
};

const createFlyer = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.CreateFlyer();
   
    return apiGateway.doPostRequest(flyerResource, payload);
};

const addCustomPrice = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.AddCustomPrice();
   
    return apiGateway.doPostRequest(flyerResource, payload);
};

const fetchMaxSeat = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.FetchMaxSeat(payload);
   
    return apiGateway.doGetRequest(flyerResource);
};

const updateFlyer = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.UpdateFlyer();
   
    return apiGateway.doPutRequest(flyerResource, payload);
};

const acceptRequest = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.AcceptRequest();
   
    return apiGateway.doPutRequest(flyerResource, payload);
};

const rejectRequestNewShop = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.RejectRequestNewShop();
   
    return apiGateway.doPutRequest(flyerResource, payload);
};

const deleteFlyer = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.DeleteFlyer(payload);
   
    return apiGateway.doDeleteRequest(flyerResource);
};

const importFlyer = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.ImportFlyer();
   
    return apiGateway.doPostRequest(flyerResource, payload);
};

const registerFlyer = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.RegisterFlyer();
   
    return apiGateway.doPostRequest(flyerResource, payload);
};

const getImporFlyerHistory = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const flyerResource = SitecoreResource.Flyer.GetImporFlyerHistory(payload);
   
    return apiGateway.doGetRequest(flyerResource);
};

export default {
    searchFlyers,
    createFlyer,
    addCustomPrice,
    fetchMaxSeat,
    updateFlyer,
    deleteFlyer,
    importFlyer,
    registerFlyer,
    getImporFlyerHistory,
    acceptRequest,
    rejectRequestNewShop
};
