import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { FlyerActionTypes, acceptRequestFail, acceptRequestSuccess } from "../../actions/flyer/flyers.actions";
import { AcceptRequestUseCase } from "../../../../domain/usecases/flyer";
import { notifyError } from "../../../../utils/toast";

export const acceptRequestEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(FlyerActionTypes.ACCEPT_REQUEST),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new AcceptRequestUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(acceptRequestSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(acceptRequestFail(error.message));
                    obs.complete();
                });
            });
        })
    );


