import { IAction } from "../../../../shared/interfaces/common";
// Action Types
export enum FlyerActionTypes {
  SEARCH_FLYERS = "[Flyers] SEARCH_FLYERS",
  SEARCH_FLYERS_SUCCESS = "[Flyers] SEARCH_FLYERS_SUCCESS",
  SEARCH_FLYERS_FAIL = "[Flyers] SEARCH_FLYERS_FAIL",

  CREATE_FLYERS = "[Flyers] CREATE_FLYER",
  CREATE_FLYERS_SUCCESS = "[Flyers] CREATE_FLYER_SUCCESS",
  CREATE_FLYERS_FAIL = "[Flyers] CREATE_FLYER_FAIL",

  ADD_CUSTOM_PRICE_FLYER = "[Flyers] ADD_CUSTOM_PRICE_FLYER",
  ADD_CUSTOM_PRICE_FLYER_SUCCESS = "[Flyers] ADD_CUSTOM_PRICE_FLYER_SUCCESS",
  ADD_CUSTOM_PRICE_FLYER_FAIL = "[Flyers] ADD_CUSTOM_PRICE_FLYER_FAIL",

  FETCH_MAX_SEAT = "[Flyers] FETCH_MAX_SEAT",
  FETCH_MAX_SEAT_SUCCESS = "[Flyers] FETCH_MAX_SEAT_SUCCESS",
  FETCH_MAX_SEAT_FAIL = "[Flyers] FETCH_MAX_SEAT_FAIL",

  ACCEPT_REQUEST = "[Flyers] ACCEPT_REQUEST",
  ACCEPT_REQUEST_SUCCESS = "[Flyers] ACCEPT_REQUEST_SUCCESS",
  ACCEPT_REQUEST_FAIL = "[Flyers] ACCEPT_REQUEST_FAIL",

  REJECT_REQUEST_NEW_SHOP = "[Flyers] REJECT_REQUEST_NEW_SHOP",
  REJECT_REQUEST_NEW_SHOP_SUCCESS = "[Flyers] REJECT_REQUEST_NEW_SHOP_SUCCESS",
  REJECT_REQUEST_NEW_SHOP_FAIL = "[Flyers] REJECT_REQUEST_NEW_SHOP_FAIL",

  UPDATE_FLYER = "[Flyers] UPDATE_FLYER",
  UPDATE_FLYER_SUCCESS = "[Flyers] UPDATE_FLYER_SUCCESS",
  UPDATE_FLYER_FAIL = "[Flyers] UPDATE_FLYER_FAIL",

  DELETE_FLYER = "[Flyers] DELETE_FLYER",
  DELETE_FLYER_SUCCESS = "[Flyers] SEARCH_FLYER_SUCCESS",
  DELETE_FLYER_FAIL = "[Flyers] SEARCH_FLYER_FAIL",

  IMPORT_FLYER = "[Flyers] IMPORT_FLYER",
  IMPORT_FLYER_SUCCESS = "[Flyers] IMPORT_FLYER_SUCCESS",
  IMPORT_FLYER_FAIL = "[Flyers] IMPORT_FLYER_FAIL",

  REGISTER_FLYER = "[Flyers] REGISTER_FLYER",
  REGISTER_FLYER_SUCCESS = "[Flyers] REGISTER_FLYER_SUCCESS",
  REGISTER_FLYER_FAIL = "[Flyers] REGISTER_FLYER_FAIL",

  GET_IMPORT_FLYER_HISTORY = "[Flyers] GET_IMPORT_FLYER_HISTORY",
  GET_IMPORT_FLYER_HISTORY_SUCCESS = "[Flyers] GET_IMPORT_FLYER_HISTORY_SUCCESS",
  GET_IMPORT_FLYER_HISTORY_FAIL = "[Flyers] GET_IMPORT_FLYER_HISTORY_FAIL",

}

export const searchFlyer = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.SEARCH_FLYERS,
  payload,
});

export const searchFlyerSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.SEARCH_FLYERS_SUCCESS,
  payload,
});

export const searchFlyerFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.SEARCH_FLYERS_FAIL,
  error,
});

//create flyer
export const createFlyer = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.CREATE_FLYERS,
  payload,
});

export const createFlyerSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.CREATE_FLYERS_SUCCESS,
  payload,
});

export const createFlyerFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.CREATE_FLYERS_FAIL,
  error,
});
//add Custom Price flyer
export const addCustomPrice = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.ADD_CUSTOM_PRICE_FLYER,
  payload,
});

export const addCustomPriceSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.ADD_CUSTOM_PRICE_FLYER_SUCCESS,
  payload,
});

export const addCustomPriceFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.ADD_CUSTOM_PRICE_FLYER_FAIL,
  error,
});

//fetch max seat
export const fetchMaxSeat = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.FETCH_MAX_SEAT,
  payload,
});

export const fetchMaxSeatSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.FETCH_MAX_SEAT_SUCCESS,
  payload,
});

export const fetchMaxSeatFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.FETCH_MAX_SEAT_FAIL,
  error,
});

//accept Request
export const acceptRequest = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.ACCEPT_REQUEST,
  payload,
});

export const acceptRequestSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.ACCEPT_REQUEST_SUCCESS,
  payload,
});

export const acceptRequestFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.ACCEPT_REQUEST_FAIL,
  error,
});

//fetch max seat
export const rejectRequestNewShop = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.REJECT_REQUEST_NEW_SHOP,
  payload,
});

export const rejectRequestNewShopSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.REJECT_REQUEST_NEW_SHOP_SUCCESS,
  payload,
});

export const rejectRequestNewShopFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.REJECT_REQUEST_NEW_SHOP_FAIL,
  error,
});
// update flyer
export const updateFlyer = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.UPDATE_FLYER,
  payload,
});

export const updateFlyerSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.UPDATE_FLYER_SUCCESS,
  payload,
});

export const updateFlyerFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.UPDATE_FLYER_FAIL,
  error,
});
//delete  flyer
export const deleteFlyer = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.DELETE_FLYER,
  payload,
});

export const deleteFlyerSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.DELETE_FLYER_SUCCESS,
  payload,
});

export const deleteFlyerFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.DELETE_FLYER_FAIL,
  error,
});
//import flyer
export const importFlyer = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.IMPORT_FLYER,
  payload,
});

export const importFlyerSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.IMPORT_FLYER_SUCCESS,
  payload,
});

export const importFlyerFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.IMPORT_FLYER_FAIL,
  error,
});

//register flyer
export const registerFlyer = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.REGISTER_FLYER,
  payload,
});

export const registerFlyerSuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.REGISTER_FLYER_SUCCESS,
  payload,
});

export const registerFlyerFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.REGISTER_FLYER_FAIL,
  error,
});

//get import flyer history
export const getImportFlyerHistory = (payload: any): IAction<any> => ({
  type: FlyerActionTypes.GET_IMPORT_FLYER_HISTORY,
  payload,
});

export const getImportFlyerHistorySuccess = (payload?: any): IAction<any> => ({
  type: FlyerActionTypes.GET_IMPORT_FLYER_HISTORY_SUCCESS,
  payload,
});

export const getImportFlyerHistoryFail = (error: any): IAction<any> => ({
  type: FlyerActionTypes.GET_IMPORT_FLYER_HISTORY_FAIL,
  error,
});