import FlyerRepository from "../../../data/repository/flyer";
import ResponseModel from "../../../models/response";
import ValidationResult from "../../../models/validationResult";
import { IUseCase } from "../../../shared/interfaces/common/usecase";

export default class RejectRequestNewShopUseCase implements IUseCase {
    private flyerRepository: FlyerRepository;

    constructor(public payload: any) {
        this.flyerRepository = new FlyerRepository();
    }

    validate = (): ValidationResult => {
            return new ValidationResult(true);
    };

    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            const validationResult = this.validate();
            if (validationResult.isValid) {
                this.flyerRepository.rejectRequestNewShop(this.payload)
                    .then((response: any) => {
                        if (response.data.success) {
                            resolve(response.data);
                        } else {
                            reject(ResponseModel.createError(500, response.data.errorMessage));
                        }
                    })
                    .catch((error: any) => {
                        reject(ResponseModel.createError(500, JSON.stringify(error)));
                    });
            }
        });
    };
}
