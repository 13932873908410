import SaleRepository from "../../../../data/repository/sale";
import ResponseModel from "../../../../models/response";
import { IUpdateSalePayload } from "../../../../presentation/redux/actions/interfaces/saleInfo.types";
import { IUseCase } from "../../../../shared/interfaces/common/usecase";
import { notifyError, notifySuccess } from "../../../../utils/toast";

export default class UpdateSaleUseCase implements IUseCase {
  private saleRepository: SaleRepository;

  constructor(public payload: IUpdateSalePayload) {
    this.saleRepository = new SaleRepository();
  }

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      this.saleRepository
        .updateSale(this.payload)
        .then((response: any) => {
          console.log("response.data", response.data);
          if (response.data && response.data.success && response.data.data) {
            notifySuccess("更新しました");

            resolve(response.data);
          } else {
            if (response.data && response.data.errorMessage) {
              notifyError(response.data.errorMessage);
            }

            reject(ResponseModel.createError(500, response.data.errorMessage));
          }
        })
        .catch(reject);
    });
  };
}
